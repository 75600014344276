import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { Container } from 'styled-minimal';
import { Button, Grid, Popup } from 'semantic-ui-react';
import EditLead from '../containers/EditLead';
import { disassociateLead } from '../actions';
import moment from 'moment';

const formatPhoneNumber = (phoneNumberString) => {
  let cleaned = ('' + phoneNumberString).replace(/\D/g, '');

  if (cleaned.length === 11 && cleaned.startsWith('1')) {
    cleaned = cleaned.substring(1);
  }

  const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
  return match ? `(${match[1]}) ${match[2]}-${match[3]}` : phoneNumberString;
};

const formatDistance = (distance) => {
  return distance ? `${Number(distance).toLocaleString()} Miles` : '';
};

const LeadNameAndLabelContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-content: space-around;  
`;

const LeadName = styled.h2`
  position: relative;
  top: 25px;
  margin-right: 20px;
`;

const LeadLabel = styled.h2`
  margin-right: 20px;
  font-weight: bold;
  color: ${props => props.isFresh ? 'green' : 'red'};
`;

const LeadContainer = styled(Container)`
  align-items: left;
  display: flex;
  flex-direction: column;
`;

const Label = styled.b`
  margin-top: 10px;
`;


const LeadId = styled.span`
  margin-top: 15px;
  font-weight: bold;
`;


const sourceMap = {
  '2M': '2 Movers',
  GOT: 'Got Movers',
  ORGANIC: 'Got Movers',
  'BVL(G)': 'Budget Van Lines',
  BVL: 'Budget Van Lines',
  MP: 'Movers Pro',
  QR: 'Quote Runner',
  RVL: 'Red Van Lines'
};

const states = {
  "AL": "Alabama",
  "AK": "Alaska",
  "AZ": "Arizona",
  "AR": "Arkansas",
  "AS": "American Samoa",
  "CA": "California",
  "CO": "Colorado",
  "CT": "Connecticut",
  "DE": "Delaware",
  "DC": "District of Columbia",
  "FL": "Florida",
  "GA": "Georgia",
  "GU": "Guam",
  "HI": "Hawaii",
  "ID": "Idaho",
  "IL": "Illinois",
  "IN": "Indiana",
  "IA": "Iowa",
  "KS": "Kansas",
  "KY": "Kentucky",
  "LA": "Louisiana",
  "ME": "Maine",
  "MD": "Maryland",
  "MA": "Massachusetts",
  "MI": "Michigan",
  "MN": "Minnesota",
  "MS": "Mississippi",
  "MO": "Missouri",
  "MT": "Montana",
  "NE": "Nebraska",
  "NV": "Nevada",
  "NH": "New Hampshire",
  "NJ": "New Jersey",
  "NM": "New Mexico",
  "NY": "New York",
  "NC": "North Carolina",
  "ND": "North Dakota",
  "MP": "Northern Mariana Islands",
  "OH": "Ohio",
  "OK": "Oklahoma",
  "OR": "Oregon",
  "PA": "Pennsylvania",
  "PR": "Puerto Rico",
  "RI": "Rhode Island",
  "SC": "South Carolina",
  "SD": "South Dakota",
  "TN": "Tennessee",
  "TX": "Texas",
  "TT": "Trust Territories",
  "UT": "Utah",
  "VT": "Vermont",
  "VA": "Virginia",
  "VI": "Virgin Islands",
  "WA": "Washington",
  "WV": "West Virginia",
  "WI": "Wisconsin",
  "WY": "Wyoming"
}

const mapSource = source => {
  const name = Object.keys(sourceMap).find(alias => source === alias || source.startsWith(alias));
  return sourceMap[name] || source;
}

const mapState = alias => {
  return states[alias] || alias;
}

const LeadInfo = ({
  buyerAvailability: {
    status,
    message
  },
  currentLead: {
    id,
    company,
    dispositions,
    inbound_call_id,
    name,
    email,
    phone,
    from,
    to,
    distance,
    movedate,
    movesize,
    source,
    openDate,
    appointment_lead_id,
    searched,
    isFresh,
    isLocal,
  },
  isAdmin,
  isDialed,
  isSearched,
  dispositionsByID,
  currentLead,
  dispatch
}) => {
  const nameOnly = name.split(" ")[0].trim();
  return (
    <Grid>
      <Grid.Row columns={2}>
        <Grid.Column width={14}>
            <LeadContainer>
              <LeadId>#{id}</LeadId>
              {
                appointment_lead_id && inbound_call_id ? <label style={{padding:10, paddingLeft:0, paddingBottom:0}}><strong style={{ backgroundColor :"#FFFF00", padding:10}}>Appointment Available! <a rel='noreferrer' target="_blank" href={`https://www.p4pmarketing.com/manager/leads/lead_call_post.php?lead_id=${appointment_lead_id}`} >Book Appointment</a> </strong> </label>: ''
              }

              <LeadNameAndLabelContainer>
                <LeadName style={{ top: 0 }}>{name}</LeadName>
                {isFresh && <LeadLabel isFresh style={{ marginTop: 0 }}>**FRESH LEAD**</LeadLabel>}
                {isLocal && <LeadLabel isLocal style={{ marginTop: 0 }}>**LOCAL LEAD**</LeadLabel>}
              </LeadNameAndLabelContainer>
              <div style={{ display: "flex" }}>
                <div style={{ display: "flex", flexDirection: "column", minWidth: "400px" }}>
                  <h3>Source - {mapSource(source)}</h3>
                  <Label>Phone:</Label>
                  <span>{formatPhoneNumber(phone)}</span>
                  <Label>From:</Label>
                  <span>{from}</span>
                  <Label>To:</Label>
                  <span>{to}</span>

                  {
                    (distance) ? <><Label>Move Distance:</Label><span>{formatDistance(distance)}</span></> : ''
                  }

                  <Label>Move Date:</Label>
                  <span
                    style={{ color: (moment(movedate).isBefore("2020-08-01") && company && company.name.includes('All My Sons') && [0, 5, 6].includes(moment(movedate).day())) ? 'red' : 'black' }}>{moment(movedate).format("M/DD/YYYY")}</span>
                  {(moment(movedate).isBefore("2020-08-01") && company && company.name.includes('All My Sons') && [0, 5, 6].includes(moment(movedate).day())) &&
                    <span style={{ color: 'red' }}>All My Sons blackout date</span>}
                  <Label>Move Size:</Label>
                  <span>{movesize}</span>
                  <Label>Email:</Label>
                  <span>{email}</span>
                  <Label>Matched Buyer:</Label>
                  <span>{company.name} - {formatPhoneNumber(company.phone)}</span>
                </div>
                <div style={{ marginTop: "80px", minWidth: "600px                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                       " }}>
                  {currentLead.from_state !== currentLead.to_state && ! inbound_call_id && (<>
                    <p style={{ fontWeight: "bold" }}>Hello, {nameOnly}? <span style={{ color: "gray" }}>(Pause)</span>
                    </p>
                    <p>Hi, this is [Agent Name], on a recorded line regarding your price request for a move
                      from {mapState(currentLead.from_state)} to {mapState(currentLead.to_state)}. Is that right?</p>
                    <p>And you're moving a {movesize}? <span style={{ color: "gray" }}>(Pause)</span></p>
                    <p>Okay great, in order to price this out for you, I am going to transfer you to Full Service Moving
                      Specialist.<span style={{ color: "gray" }}>(Pause)</span></p>
                    <p>Alright {nameOnly} I’m connecting you right now, please stay on the line.</p>
                  </>)}
                </div>
              </div>
            </LeadContainer>
        </Grid.Column>
        <Grid.Column width={2}>
          <div style={{ textAlign: 'right' }}>
            {searched && isAdmin ?
              <Popup content='Drop lead' trigger={<Button icon="user delete" color="red" onClick={() => {
                dispatch(disassociateLead(currentLead.id));
              }}></Button>}/> : null}
            <EditLead id="update-lead-button">Update Lead</EditLead>
          </div>
        </Grid.Column>
        {
            isLocal && (
                <p style={{padding: '20px', marginLeft: '25px'}}>
                  <LeadLabel isFresh={status}>
                    {message}
                  </LeadLabel>
                </p>
            )
        }
      </Grid.Row>
    </Grid>
  );
};
LeadInfo.propTypes = {
  lead: PropTypes.object.isRequired,
  dispatch: PropTypes.func.isRequired,
};

function mapStateToProps(state) {
  return {
    currentLead: state.lead.currentLead,
    isAdmin : state.user.isAdmin,
    buyerAvailability: state.lead.buyerAvailability,
  };
}


export default connect(mapStateToProps)(LeadInfo); ;
