import React from 'react';
import { connect } from 'react-redux';
import { Button, Container, Checkbox, Dropdown } from 'semantic-ui-react';
import CreateLead from '../containers/CreateLead';
import { setAutoPull, requestLead, changeAgentStatus, getAgent } from '../actions';
import "./LeadOperations.css";

const agentStatusOptions = [
  { key: 'online', text: 'Online', value: 'ONLINE' },
  { key: 'offline', text: 'Offline', value: 'OFFLINE', disabled: true },
  { key: 'break', text: 'Break', value: 'BREAK' },
  { key: 'lunch', text: 'Lunch', value: 'LUNCH' },
  { key: 'training', text: 'Training', value: 'TRAINING' },
  { key: 'qa_work', text: 'QA work', value: 'QA_WORK' },
  { key: 'meeting', text: 'Meeting', value: 'MEETING' },
  { key: 'admin_work', text: 'Admin work', value: 'ADMIN_WORK' },
  { key: 'on_local_call', text: 'Local call', value: 'ON_LOCAL_CALL', disabled: true },
  { key: 'on_long_distance_call', text: 'Long Distance call', value: 'ON_LONG_DISTANCE_CALL', disabled: true },
  { key: 'on_inbound_call', text: 'Inbound call', value: 'ON_INBOUND_CALL', disabled: true },
];

const LeadOperationsContainer = {
  display: 'flex',
  flexDirection: 'row',
  height: '60px',
  width: '1000px',
  justifyContent: 'space-around',
};

const createLeadButton = {
  color: '#00827c',
  backgroundColor: 'transparent',
  border: '2px solid',
  width: '150px',
  height: '40px',
  padding: '10px',
  borderRadius: '3px',
};

const coverageStatusDiv = {
  marginTop: '10px',
  fontWeight: 'bold',
};

const autoPullCheckbox = {
  marginTop: '10px',
};

export class LeadOperations extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      intervalRunning: false,
      coverageStatusText: '',
    };
  }

  componentDidMount() {
    const { dispatch } = this.props;
    dispatch(getAgent());
  }

  componentDidUpdate(prevProps) {
    if (this.props.leadState && prevProps.leadState !== this.props.leadState) {
      this.setState({ loading: false });
    }

    if (!prevProps.coverageAvailable && this.props.coverageAvailable) {
      this.setState({ loading: false });
    }
  }

  handlePauseAutoPullClick(_, data) {
    const { dispatch } = this.props;
    dispatch(setAutoPull(data.checked));
  }

  handleRequestLead() {
    this.setState({ loading: true });
    const { dispatch } = this.props;
    dispatch(requestLead());
  }

  handleAgentStatusChange(_, data) {
    const { dispatch } = this.props;
    const agentStatus = data.value;
    dispatch(changeAgentStatus(agentStatus));
  }

  render() {
    const {
      coverageAvailable,
      exceptionReported,
      leadHasBuyer,
      leadId,
      leadState,
      leadSearched,
      loading,
      inboundCallCompleted,
      agentStatus,
    } = this.props;

    const requestLeadDisabled = !exceptionReported && leadId !== null &&  !inboundCallCompleted && (loading || !coverageAvailable || (leadHasBuyer && !['dispositioned','transferred'].includes(leadState) && !leadSearched))

    return (
      <Container style={LeadOperationsContainer}>
        <div style={coverageStatusDiv}>
          <span>{this.state.coverageStatusText}</span>
        </div>

        <div style={{ width: '200px', display: 'flex', flexDirection: 'column' }} className="agent-status">
          <Dropdown
            placeholder={agentStatusOptions.find(item => item.key === agentStatus)?.text || 'Select status'}
            className={agentStatus?.toLowerCase()}
            fluid
            selection
            options={agentStatusOptions}
            value={agentStatus}
            disabled={["ON_LOCAL_CALL", "ON_LONG_DISTANCE_CALL", "ON_INBOUND_CALL"].includes(agentStatus)}
            onChange={this.handleAgentStatusChange.bind(this)}
          />
        </div>

        <Button
          id="request-lead-button"
          style={createLeadButton}
          onClick={this.handleRequestLead.bind(this)}
          loading={this.state.loading}
          disabled={requestLeadDisabled || this.state.loading || agentStatus !== "ONLINE"}
        >
          Request & Dial Lead
        </Button>

        <CreateLead id="create-lead-button">
          Create New Lead
        </CreateLead>
      </Container>
    );
  }
}

function mapStateToProps(state) {
  return {
    coverageAvailable: state.app.coverageAvailable,
    loading: state.lead.currentLead.status === "running",
    leadState: state.lead.currentLead.state,
    leadSearched: state.lead.currentLead.searched,
    leadId: state.lead.currentLead.id,
    leadHasBuyer: typeof state.lead.currentLead.company === "object" && !state.lead.currentLead.company.name.includes("No Buyer Available"),
    exceptionReported: state.lead.currentLead.disposition === "exception",
    inboundCallCompleted: (state.call.state==='hangup' && state.call.callUUID && state.lead.currentLead.inbound_call_id > 0),
    agentStatus: state.user.agentStatus,
  };
}

export default connect(mapStateToProps)(LeadOperations);
