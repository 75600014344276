import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { Button, Container } from 'semantic-ui-react';
import { dialLead, transferLead, hangup, getCache, disassociateLead } from '../../actions/index';
import './CallManagement.css';


const dialDisabledStates = {
  call_initiated: true,
  call_live: true,
};

const loadingStates = {
  call_initiated: true,
  transferring: true,
  dialing: true,
};

const transferDisabledStates = {
  call_initiated: true,
  hangup: true,
  transfer_success: true,
  transfer_fail: true,
  dialing: true,
  incoming_transfer: true,
  transferring: true,
  error_connecting: true,
};

const transferButtons = {
  transfer: {
    id: 'transfer-button',
    label: 'Transfer',
    clickHandler: 'handleTransferClick'
  },
  buyerUnavailable: {
    id: 'transfer-button',
    label: 'No Buyer Available',
    clickHandler: 'handleBuyerUnavailableClick'
  }
}

export class CallManagement extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = { loading: false };
    this.timeouts = {};
  }
  
  componentDidUpdate = prevProps => {
    const currentCallState = this.props.call.state;
    if (prevProps.call.state !== currentCallState) {
      this.setState({ loading: false });
    } else if (currentCallState === 'transfer_success') {
      this.setState({ loading: false });
    } else if (currentCallState === 'error_connecting') {
      this.setState({ loading: false });
    } else if (this.props.leadHangupUpdate !== prevProps.leadHangupUpdate) {
      this.setState({ loading: false });
    }

    if (['live_with_customer', 'error_connecting', 'hangup'].includes(currentCallState)) {
      clearTimeout(this.timeouts.dial);
    }

    if (['transfer_success', 'transfer_fail', 'hangup'].includes(currentCallState)) {
      if (currentCallState === 'transfer_success') {
        this.setState({ loading: false });
      }
      clearTimeout(this.timeouts.transfer);
    }
  };

  handleDialClick = () => {
    const { dispatch } = this.props;
    this.setState({ loading: true });
    const timeout = setTimeout(
      that => {
        that.timeouts.dial = timeout;
        dispatch(getCache());
      },
      15000,
      this,
    );
    dispatch(dialLead());
  };

  handleBuyerUnavailableClick = () => {
    const { dispatch, lead } = this.props;
    dispatch(disassociateLead(lead.id));
  }

  handleTransferClick = () => {
    const { dispatch, lead } = this.props;
    this.setState({ loading: true });
    const timeout = setTimeout(
      that => {
        that.timeouts.transfer = timeout;
        dispatch(getCache());
      },
      15000,
      this,
    );
    dispatch(transferLead({ toBVL: false, selfService: false, toCompany: lead.company.id, miniMove: false }));
  };

  handleBVLTransfer = () => {
    const { dispatch } = this.props;
    this.setState({ loading: true });
    const timeout = setTimeout(
      that => {
        that.timeouts.transfer = timeout;
        dispatch(getCache());
      },
      15000,
      this,
    );
    dispatch(transferLead({ toBVL: true, selfService: false, miniMove: false }));
  };

  handleHangupClick = () => {
    this.setState({ loading: true });
    const { dispatch } = this.props;
    dispatch(hangup());
  };

  renderTransferButton = config => {
    const { call, lead } = this.props;
    const { id, label, clickHandler } = config;

    return (
        <Button
            id={id}
            onClick={this[clickHandler].bind(this)}
            loading={loadingStates[call.state] || this.state.loading}
            disabled={
                transferDisabledStates[call.state] ||
                this.state.loading ||
                lead.id !== call.lead_id ||
                lead.company.phone === undefined
            }
        >
          {label}
        </Button>
    )
  }

  render() {
    const { call, lead, usesNoble } = this.props;

    return (
      <Container id="call-management-container">
        <Button
          id="dial-button"
          onClick={this.handleDialClick.bind(this)}
          loading={loadingStates[call.state] || this.state.loading}
          disabled={dialDisabledStates[call.state] || this.state.loading}
        >
          Dial
        </Button>

        {
           this.renderTransferButton(transferButtons.transfer)
        }

        {usesNoble ? (
          <Button
            id="hangup-button"
            onClick={this.handleHangupClick.bind(this)}
            loading={this.state.loading}
          >
            Hangup
          </Button>
        ) : null }
      </Container>
    );
  }
}

CallManagement.propTypes = {
  dispatch: PropTypes.func.isRequired,
};


/* istanbul ignore next */
function mapStateToProps(state) {
  return {
    call: state.call,
    lead: state.lead.currentLead,
    isAdmin: state.user.isAdmin,
    leadHangupUpdate: state.call.hangup_update,
    usesNoble: state.user.tsr > 0,
  };
}

export default connect(mapStateToProps)(CallManagement);
